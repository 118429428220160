import { type IRoutingCard } from '../../components/admin/routing-cards';

export const EARTH_RADIUS_MI = 3958.8;

export const superAdminRoutingCards: IRoutingCard[] = [
  {
    icon: 'home',
    title: 'Vendor Listing',
    description: 'View and pay Vendors’ invoices',
    descriptionColor: 'text-grey-500',
    path: '/super-admin/vendor-listing',
  },
  {
    icon: 'invoices',
    title: 'Vendor Invoices',
    description: 'View and edit Vendors’ list',
    descriptionColor: 'text-grey-500',
    path: '/super-admin/vendor-invoices',
  },
  {
    icon: 'services',
    title: 'All Services',
    description: 'View and edit services’ list',
    descriptionColor: 'text-grey-500',
    path: '/super-admin/services',
  },
];

export const adminRoutingCards: IRoutingCard[] = [
  {
    icon: 'company-information',
    title: 'Company Information',
    description: "View and edit your company's information",
    path: '/admin/dashboard/company',
  },
  {
    icon: 'invoices',
    title: 'Invoices',
    description: 'Create or manage the Invoices',
    path: '/admin/invoices',
  },
  {
    icon: 'agreements-templates',
    title: 'Agreement Templates',
    description: 'Create or edit work authorization forms',
    path: '/admin/dashboard/work-order-templates',
  },
  {
    icon: 'invoices-products',
    title: 'Inventory',
    description: 'Add or remove parts and services',
    path: '/admin/dashboard/invoices/products',
  },
  {
    icon: 'customers-account',
    title: 'Customers',
    description: 'Add/remove your repeat customers for fast checkout',
    path: '/admin/dashboard/carriers',
  },
  {
    icon: 'custom-fields',
    title: 'Invoice Information',
    description: 'Create and edit information on your invoices',
    path: '/admin/dashboard/invoices/custom-fields',
  },
  {
    icon: 'user-accounts',
    title: 'User Profiles',
    description: 'Edit your user profiles to assign roles',
    path: '/admin/dashboard/user-accounts',
  },
  {
    icon: 'invoices-product-taxes',
    title: 'Tax Settings',
    description: 'Add/remove taxes for parts and services',
    path: '/admin/dashboard/invoices/products-taxes',
  },
  {
    icon: 'deposits',
    title: 'Receipts',
    description: 'View and download all payouts',
    path: '/admin/dashboard/deposits',
  },
];

export const employeeRoutingCards: IRoutingCard[] = [
  {
    icon: 'invoices',
    title: 'Invoices',
    description: 'Create or manage the Invoices',
    path: '/employee/dashboard/invoices',
  },
  {
    icon: 'deposits',
    title: 'Receipts',
    description: 'View and download all payouts',
    path: `/employee/dashboard/deposits`,
  },
];

export const getSuperAdminRoutingCards = (companyId: string) => [
  {
    icon: 'company-information',
    title: 'Company Information',
    description: "View and edit your company's information",
    path: `/super-admin/dashboard/${companyId}/company`,
  },
  {
    icon: 'invoices',
    title: 'Invoices',
    description: 'Create or manage the Invoices',
    path: `/super-admin/dashboard/${companyId}/invoices`,
  },
  {
    icon: 'agreements-templates',
    title: 'Agreement Templates',
    description: 'Create or edit work authorization forms',
    path: `/super-admin/dashboard/${companyId}/work-order-templates`,
  },
  {
    icon: 'invoices-products',
    title: 'Inventory',
    description: 'Add or remove parts and services',
    path: `/super-admin/dashboard/${companyId}/invoices/products`,
  },
  {
    icon: 'customers-account',
    title: 'Customers',
    description: 'Add/remove your repeat customers for fast checkout',
    path: `/super-admin/dashboard/${companyId}/carriers`,
  },
  {
    icon: 'custom-fields',
    title: 'Invoice Information',
    description: 'Create and edit information on your invoices',
    path: `/super-admin/dashboard/${companyId}/invoices/custom-fields`,
  },
  {
    icon: 'user-accounts',
    title: 'User Profiles',
    description: 'Edit your user profiles to assign roles',
    path: `/super-admin/dashboard/${companyId}/user-accounts`,
  },
  {
    icon: 'invoices-product-taxes',
    title: 'Tax Settings',
    description: 'Add/remove taxes for parts and services',
    path: `/super-admin/dashboard/${companyId}/invoices/products-taxes`,
  },
  {
    icon: 'deposits',
    title: 'Receipts',
    description: 'View and download all payouts',
    path: `/super-admin/dashboard/${companyId}/deposits`,
  },
];

export const partLaborOptions = [
  { label: 'Part', value: 'Part' },
  { label: 'Labor', value: 'Labor' },
];

export const formDataConfig = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};
