import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import NavigationRow from './row';
import useUser from '../../../../hooks/use-user';
import { currentUserSelector } from '../../../../store/selectors/auth';
import { companySelector } from '../../../../store/selectors/company';
import { type Icon } from '../../../shared/svg-icon';
import Typography from '../../../shared/typography';

export interface INavigationList {
  title: string;
  icon?: Icon;
  path: string;
  visible?: boolean;
  onClick?: () => void;
}

const Navigation = () => {
  const user: any = useUser();
  const authUser = useSelector(currentUserSelector);
  const company = useSelector(companySelector);

  const adminNavigation = !!user.roles.find((role: string) => role === 'Admin');
  const superAdminNavigation = !!user.roles.find((role: string) => role === 'SuperAdmin');
  const employeeNavigation = !!user.roles.find((role: string) => role === 'Employee');

  const navigationList: INavigationList[] = useMemo(
    () => [
      {
        title: 'Vendor Listing',
        path: '/super-admin/vendor-listing',
        visible: superAdminNavigation,
      },
      {
        title: 'Vendor Invoices',
        path: '/super-admin/vendor-invoices',
        visible: superAdminNavigation,
      },
      {
        title: 'All Services',
        path: '/super-admin/services',
        visible: superAdminNavigation,
      },
      { title: 'Invoices', path: '/admin/invoices', visible: adminNavigation },
      { title: 'Payments', path: '/admin/deposits', visible: adminNavigation },
    ],
    [],
  );

  const companyNavigationList = (companyId: number) => [
    {
      title: 'Company Information',
      path: `/super-admin/dashboard/${companyId}/company`,
      visible: superAdminNavigation,
    },
    {
      title: 'Invoices',
      path: `/super-admin/dashboard/${companyId}/invoices`,
      visible: superAdminNavigation,
    },
    {
      title: 'Agreement Templates',
      path: `/super-admin/dashboard/${companyId}/work-order-templates`,
      visible: superAdminNavigation,
    },
    {
      title: 'Inventory',
      path: `/super-admin/dashboard/${companyId}/invoices/products`,
      visible: superAdminNavigation,
    },
    {
      title: 'Customers',
      path: `/super-admin/dashboard/${companyId}/carriers`,
      visible: superAdminNavigation,
    },
    {
      title: 'Invoice Information',
      path: `/super-admin/dashboard/${companyId}/invoices/custom-fields`,
      visible: superAdminNavigation,
    },
    {
      title: 'User Profiles',
      path: `/super-admin/dashboard/${companyId}/user-accounts`,
      visible: superAdminNavigation,
    },
    {
      title: 'Tax Settings',
      path: `/super-admin/dashboard/${companyId}/invoices/products-taxes`,
      visible: superAdminNavigation,
    },
    {
      title: 'Receipts',
      path: `/super-admin/dashboard/${companyId}/deposits`,
      visible: superAdminNavigation,
    },
    {
      title: 'Company Information',
      path: '/admin/dashboard/company',
      visible: adminNavigation,
    },
    {
      title: 'Invoices',
      path: `/admin/invoices`,
      visible: adminNavigation,
    },
    {
      title: 'Agreement Templates',
      path: '/admin/dashboard/work-order-templates',
      visible: adminNavigation,
    },
    {
      title: 'Inventory',
      path: '/admin/dashboard/invoices/products',
      visible: adminNavigation,
    },
    {
      title: 'Customers',
      path: '/admin/dashboard/carriers',
      visible: adminNavigation,
    },
    {
      title: 'Invoice Information',
      path: '/admin/dashboard/invoices/custom-fields',
      visible: adminNavigation,
    },
    {
      title: 'User Profiles',
      path: '/admin/dashboard/user-accounts',
      visible: adminNavigation,
    },
    {
      title: 'Tax Settings',
      path: '/admin/dashboard/invoices/products-taxes',
      visible: adminNavigation,
    },
    {
      title: 'Receipts',
      path: `/admin/dashboard/deposits`,
      visible: adminNavigation,
    },
    {
      title: 'Invoices',
      path: '/employee/dashboard/invoices',
      visible: employeeNavigation,
    },
    {
      title: 'Receipts',
      path: `/employee/dashboard/deposits`,
      visible: employeeNavigation,
    },
  ];

  const getDashboardPath = () =>
    superAdminNavigation
      ? '/super-admin'
      : adminNavigation
      ? '/admin/dashboard'
      : employeeNavigation
      ? '/employee/dashboard'
      : '';

  const getCompanyDashboardPath = (companyId: number) =>
    superAdminNavigation
      ? `/super-admin/dashboard/${companyId}`
      : adminNavigation
      ? `/admin/dashboard`
      : employeeNavigation
      ? `/employee/dashboard`
      : '';

  return (
    <>
      {superAdminNavigation && (
        <div className="relative w-[250px] flex flex-col items-end gap-[10px] overflow-auto bg-[#1D1D1D] rounded-[10px]">
          <NavigationRow key="menu-icon" icon="menu-icon" title="Dashboard" path={getDashboardPath()} />
          <div className="absolute border border-solid border-[#434343] left-[18px] top-[50px] h-[calc(100%_-_86px)]" />
          <div className="pr-2 pb-2">
            {navigationList.map(row =>
              row.visible ? (
                <div key={row.path} className="mb-1">
                  <hr className="w-[10px] absolute left-5 mt-6 border border-solid border-[#434343]" />
                  <NavigationRow {...row} />
                </div>
              ) : null,
            )}
          </div>
        </div>
      )}
      {superAdminNavigation && company?.id && <hr className="h-[1px] w-auto my-5 border border-solid border-[#434343]" />}
      {(company?.id || authUser?.company?.id) && (
        <>
          <Typography tag="p" variant="none" className="text-xs text-[#ACACAC]">
            Company
          </Typography>
          <Typography tag="p" variant="none" className="font-medium	text-lg text-white py-1 h-[44px]">
            {company?.name
              ? company?.name?.length > 25
                ? `${company?.name?.substring(0, 25)}...`
                : company?.name
              : authUser?.company?.name?.length > 25
              ? `${authUser?.company?.name?.substring(0, 25)}...`
              : authUser?.company?.name}
          </Typography>
          <div className="relative w-[250px] flex flex-col items-end gap-[10px] overflow-hidden bg-[#1D1D1D] rounded-[10px]">
            <NavigationRow
              key="menu-icon"
              icon="menu-icon"
              title="Dashboard"
              path={getCompanyDashboardPath(company?.id ? company?.id : authUser?.company?.id)}
            />
            <div className="absolute border border-solid border-[#434343] left-[18px] top-[50px] h-[calc(100%_-_86px)]" />
            <div className="pr-2 pb-2">
              {companyNavigationList(company?.id ? company?.id : authUser?.company?.id).map(row =>
                row.visible ? (
                  <div key={row.path} className="mb-1">
                    <hr className="w-[10px] absolute left-5 mt-6 border border-solid border-[#434343]" />
                    <NavigationRow key={row.path} {...row} />
                  </div>
                ) : null,
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Navigation;
