import { memo } from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import useUser from '../../../../hooks/use-user';
import { setCurrentUser } from '../../../../store/actions/auth';
import { setCompany } from '../../../../store/actions/company';
import Button from '../button';

const HeaderSelect = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useUser();

  const signOut = () => {
    dispatch(setCurrentUser(null));
    dispatch(setCompany(null));
    localStorage.removeItem('access_token');
    sessionStorage.removeItem('access_token');
    navigate('/');
  };

  return (
    <div className="p-[5px]">
      <Button
        icon="chevron-right"
        iconClassName="stroke-white w-[24px] h-[24px] absolute right-[15px]"
        label="Dashboard"
        variant="text-white"
        size="icon"
        className="p-[10px] text-[18px] h-[40px] w-full !justify-start"
        onClick={() => (user?.roles.includes('SuperAdmin') ? navigate('super-admin') : navigate('admin'))}
      />
      <Button
        label="Sign Out"
        variant="text-white"
        size="icon"
        className="p-[10px] text-[18px] h-[40px] w-full !justify-start"
        onClick={signOut}
      />
    </div>
  );
};

export default memo(HeaderSelect);
