import axiosClient from '../';
import { formDataConfig } from '../../../utils/constants';
import { objectToFormData } from '../../../utils/helpers';
import mapboxGeocodingService from '../mapbox-geocoding-service';

class CompanyService {
  private readonly path: string;

  constructor() {
    this.path = '/company';
  }

  public async getCompanyNames() {
    try {
      const { data } = await axiosClient.get(`${this.path}/names`);
      return data;
    } catch (e) {
      console.error(e);
    }
  }

  public async getCompanies() {
    try {
      const { data } = await axiosClient.get(this.path);
      return data;
    } catch (e) {
      console.error(e);
    }
  }

  public async getSearchCompanies(searchKey: any) {
    try {
      const { data } = await axiosClient.get(`${this.path}/searchByParam/?searchKey=${searchKey}`);
      return data;
    } catch (e) {
      console.error(e);
    }
  }

  public async editCompany(body: any) {
    try {
      // if (body.street && body.location) body.coords = (await mapboxGeocodingService.getCoords(body)).features[0].center;
      const location = await mapboxGeocodingService.getCoords(body);
      const { data } = await axiosClient.patch(
        this.path,
        objectToFormData({ ...body, coords: location.features[0].center }),
        formDataConfig,
      );
      return data;
    } catch (e) {
      console.error(e);
    }
  }

  public async updateCompany(companyId: number, body: any) {
    try {
      const { data } = await axiosClient.patch(`${this.path}`, body, {
        headers: {
          'x-tenant-id': companyId,
        },
      });
      return data;
    } catch (e) {
      console.error(e);
    }
  }

  public async deleteCompany() {
    try {
      const { data } = await axiosClient.delete(this.path);
      return data;
    } catch (e) {
      console.error(e);
    }
  }

  public async getVisibleTypes(): Promise<any[]> {
    try {
      const { data } = await axiosClient.get(`${this.path}/types`);
      return data;
    } catch (e: any) {
      console.error(e.message);
      return [];
    }
  }

  public async getCompanyById() {
    try {
      const { data } = await axiosClient.get(this.path);
      return data;
    } catch (e) {
      console.error(e);
    }
  }

  public async createVisibleTypes(body: any) {
    const { data } = await axiosClient.post(`${this.path}/type`, body);
    return data;
  }

  public async editTypes(body: any, id: string) {
    try {
      const { data } = await axiosClient.patch(`${this.path}/type/${id}`, body);
      return data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  public async deleteTypes(id: string) {
    try {
      const { data } = await axiosClient.delete(`${this.path}/type/${id}`);
      return data;
    } catch (e) {
      console.error(e);
    }
  }

  public async getTypesInfo(id: string) {
    try {
      const { data } = await axiosClient.get(`${this.path}/type-supported/${id}`);
      return data;
    } catch (e) {
      console.error(e);
    }
  }

  public async createCompany(body: any) {
    try {
      const location = await mapboxGeocodingService.getCoords(body);
      const { data } = await axiosClient.post(
        this.path,
        objectToFormData({ ...body, coords: location.features[0].center }),
        formDataConfig,
      );
      return data;
    } catch (e) {
      console.error(e);
    }
  }

  public async search({ typeId, fullAddress }: any) {
    try {
      const { data } = await axiosClient.get(`${this.path}/search`, {
        params: {
          typeId,
          fullAddress,
        },
      });
      return data;
    } catch (e) {
      console.error(e);
    }
  }
}

export default new CompanyService();
