import { Link, useLocation } from 'react-router-dom';

import { LightTooltip } from '../../../../shared/input-sources/tooltip';
import SvgIcon from '../../../../shared/svg-icon';
import Typography from '../../../../shared/typography';
import { type INavigationList } from '../index';

const NavigationRow = ({ icon, title, path, onClick }: INavigationList) => {
  const location = useLocation();

  const activeClassName =
    location.pathname == path
      ? icon
        ? '!w-full bg-[rgba(0,0,0,0.3)] border rounded-[10px] border-[rgba(235,235,235,1)]'
        : 'border rounded-[10px] bg-[rgba(0,0,0,0.3)] border-[rgba(235,235,235,1)]'
      : icon
      ? '!w-full bg-[#131313] hover:opacity-50'
      : 'border-transparent hover:bg-[#2A2A2A] hover:rounded-[10px]';
  return (
    <LightTooltip title={title} placement="right" className={title.length > 14 ? '' : 'hidden'}>
      <Link
        to={path}
        onClick={() => {
          onClick ? onClick() : '';
        }}
        className={`w-[210px] h-[50px] flex flex-row px-[19px] pt-[15px] pb-[17px] gap-[14px] items-center ${activeClassName}`}
      >
        {icon && <SvgIcon icon={icon} className="w-[24px] h-[24px] stroke-white" />}
        <Typography
          variant="none"
          className={`${icon ? 'text-white' : 'text-[#ACACAC]'} font-medium text-[20px] medium:text-sm whitespace-nowrap`}
          tag="b"
        >
          {title.length > 14 ? `${title.substring(0, 14)}...` : title}
        </Typography>
      </Link>
    </LightTooltip>
  );
};

export default NavigationRow;
