import { forwardRef, type InputHTMLAttributes, memo, type ReactNode, useState } from 'react';

import SvgIcon, { type Icon } from '../../svg-icon';
import Typography from '../../typography';

export interface IInputProps extends InputHTMLAttributes<any> {
  inputClassName?: string;
  helperTextClassName?: string;
  type?: 'text' | 'password' | 'email' | 'tel' | 'number' | 'radio' | 'checkbox';
  error?: boolean;
  helperText?: any;
  placeholderColorClass?: string;
  iconColorClass?: string;
  prefixIcon?: Icon;
  readonly?: boolean;
  prefixClassName?: string;
  active?: string;
  onClickIcon?: () => void;
  icon?: Icon;
  setActive?: any;
  forceFix?: boolean;
  radioButtonName?: string;
  prefixClasses?: string;
  suffixElement?: ReactNode;
}

const Input = (
  {
    type = 'text',
    error,
    helperText,
    inputClassName = '',
    helperTextClassName,
    iconColorClass,
    prefixIcon,
    prefixClassName,
    prefix,
    placeholderColorClass = '',
    readOnly = false,
    forceFix = false,
    active,
    setActive,
    radioButtonName,
    icon,
    onClickIcon,
    prefixClasses,
    suffixElement,
    ...rest
  }: IInputProps,
  ref: any,
) => {
  const placeholderClasses = `${placeholderColorClass || 'placeholder:text-grey-500'} placeholder:font-medium`;
  const [show, setShow] = useState(false);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      onClickIcon && onClickIcon();
    }
  };

  return (
    <>
      {type === 'radio' ? (
        <label className="relative flex cursor-pointer items-center rounded-full p-3" htmlFor="purple">
          <input
            value={active}
            id="purple"
            name="color"
            checked={active === radioButtonName}
            onChange={setActive && (() => setActive(radioButtonName))}
            type="radio"
            className={`before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border-[2px] border-blue-gray-200 text-[#515B75] transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-blue-500 hover:before:opacity-10 border-[#515B75] ${inputClassName}`}
          />
          <div className="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-[#515B75] opacity-0 transition-opacity peer-checked:opacity-100 peer-checked:text-blue-500">
            <svg className="h-3.5 w-3.5" viewBox="0 0 16 16" fill="currentColor">
              <circle data-name="ellipse" cx="8" cy="8" r="8"></circle>
            </svg>
          </div>
        </label>
      ) : (
        <div ref={ref} className="flex flex-col items-center justify-center gap-1 w-full relative">
          <div className="flex items-center justify-center w-full relative">
            {prefixIcon && (
              <SvgIcon
                icon={prefixIcon}
                className={`w-[24px] h-[24px] medium:w-[16px] medium:h-[16px] ${
                  iconColorClass ? iconColorClass : 'stroke-grey-500'
                } absolute left-[13px] cursor-pointer ${prefixClassName}`}
              />
            )}
            {prefix && (
              <Typography
                tag="span"
                variant="body-3"
                className={`${prefixClasses} absolute ${error ? 'text-red-500' : ''} w-fit left-[15px] ${prefixClassName}`}
              >
                {prefix}
              </Typography>
            )}

            <input
              type={type === 'password' && show ? 'text' : type}
              readOnly={readOnly}
              onMouseDown={e => {
                if (rest.onMouseDown) {
                  rest.onMouseDown(e);
                }

                if (type === 'number' && +e.currentTarget.value === 0) {
                  e.currentTarget.value = '';
                }
              }}
              onBlur={e => {
                if (rest.onBlur) {
                  rest.onBlur(e);
                }

                if (type === 'number' && e.currentTarget.value === '') {
                  e.currentTarget.value = '0';
                }

                if (type === 'number' && Number(e.currentTarget.value) !== 0 && !forceFix) {
                  e.currentTarget.value = Number(e.currentTarget.value).toFixed(2);
                }

                if (forceFix) {
                  e.currentTarget.value = parseInt(e.currentTarget.value).toString();
                }
              }}
              onChange={e => {
                if (type === 'number' && e.currentTarget.value.startsWith('0') && e.currentTarget.value[2] !== '.') {
                  e.currentTarget.value = parseFloat(e.currentTarget.value).toString();
                }

                if (rest.onChange) {
                  rest.onChange(e);
                }
              }}
              onKeyDown={(e: any) => handleKeyDown(e)}
              onWheel={(e: any) => e.target.blur()}
              {...rest}
              className={`px-[15px] py-[10px] outline-none w-full h-[50px] medium:h-[40px] ${inputClassName} ${placeholderClasses} ${
                error ? 'border-red-500' : 'border-transparent'
              } border-solid border-[1px] rounded-[10px] ${prefixIcon ? 'pl-[40px] medium:pl-[35px]' : ''} ${
                prefix ? 'pl-[30px]' : ''
              } ${rest.className}`}
            />
            {icon && (
              <SvgIcon
                icon={icon ? icon : !show ? 'eye-closed' : 'eye-open'}
                onClick={onClickIcon && onClickIcon}
                className={`w-[24px] h-[24px] ${
                  iconColorClass ? iconColorClass : 'stroke-grey-500'
                } absolute right-[17px] cursor-pointer`}
              />
            )}
            {suffixElement && <div className="absolute right-[5px] cursor-pointer">{suffixElement}</div>}

            {type === 'password' && (
              <SvgIcon
                icon={!show ? 'eye-closed' : 'eye-open'}
                onClick={() => setShow(!show)}
                className={`w-[24px] h-[24px] ${
                  iconColorClass ? iconColorClass : 'stroke-grey-500'
                } absolute right-[17px] cursor-pointer`}
              />
            )}
          </div>

          {helperText && (
            <Typography
              tag="span"
              variant="error-message"
              className={`${helperTextClassName} ${error ? 'text-red-500' : ''} w-full z-100 text-[12px]`}
            >
              {helperText}
            </Typography>
          )}
        </div>
      )}
    </>
  );
};

export default memo(forwardRef(Input));
