import { Tooltip, type TooltipProps, styled, tooltipClasses } from '@mui/material';

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} enterDelay={200} leaveDelay={0} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#010300',
    color: '#FEFEFD;',
    boxShadow: '0px 0px 150px rgba(0, 0, 0, 0.3), 0px 0px 10px rgba(0, 0, 0, 0.4)',
    fontSize: 16,
    borderRadius: '8px',
    fontFamily: 'Ubuntu',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '120%',
    padding: '5px 10px',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#010300',
    fontSize: 15,
  },
}));

interface IProps {
  children: JSX.Element;
  content: string;
  bottom?: boolean;
  open?: boolean;
  hover?: string;
}

const CustomTooltip = ({ open, children, content, hover, bottom = false }: IProps) => (
  <LightTooltip open={open && open} title={content} arrow placement={bottom ? 'bottom' : 'top'}>
    <span className={`${hover ? hover : 'hover:bg-gray-200'} p-[5px] rounded-full`}>{children}</span>
  </LightTooltip>
);

export default CustomTooltip;
