import { Link, useNavigate } from 'react-router-dom';

import ContactInfo, { type IContact } from '../../home/contact-us/contact-info';
import Button from '../../shared/input-sources/button';
import Logo from '../../shared/logo';
import SvgIcon from '../../shared/svg-icon';
import Typography from '../../shared/typography';

const Footer = () => {
  const contactInfo: IContact[] = [
    {
      icon: 'instagram',
      link: 'https://www.instagram.com/roadifix/?utm_source=qr&igshid=NGExMmI2YTkyZg%3D%3D',
    },
    {
      icon: 'phone',
    },
    {
      icon: 'mail',
    },
  ];

  const navigate = useNavigate();

  return (
    <footer className=" bg-black-1 flex justify-center">
      <div className="w-[1680px] medium:!w-full text-white gap-[40px] mt-auto flex-row gap-[20] px-[120px] medium:px-[40px] p-[40px] flex mobile:flex-col items-center justify-between shadow-lg ">
        <div>
          <Logo color="white" />
          <Typography tag="b" variant="body-2" className="text-gray-500 flex mobile:hidden pt-[30px]">
            © {new Date().getFullYear()} Roadifix Inc. All rights reserved
          </Typography>
        </div>
        <ContactInfo contacts={contactInfo} />
        <div className="flex flex-row gap-[20px]">
          <Link to="https://apps.apple.com/us/app/roadifix/id6474563092" target="_blank">
            <SvgIcon icon="app-store" className="h-[40px] w-[120px] hover:invert-[10%] cursor-pointer" />
          </Link>
          <Link to="https://play.google.com/store/apps/details?id=road.fix" target="_blank">
            <SvgIcon icon="google-play" className="h-[40px] w-[135px] hover:invert-[10%] cursor-pointer" />
          </Link>
        </div>
        <div className="mobile:flex-col flex-row gap-[14px] mobile:flex hidden">
          <Button
            variant="text"
            size="none"
            label="Privacy Policy"
            className="text-sm hover:border-b-[1px] !rounded-[0px] border-b-[1px] border-transparent hover:border-white"
            onClick={() => navigate('/privacy-policy')}
          />
          <Button
            variant="text"
            size="none"
            label="Terms & Conditions"
            className="text-sm hover:border-b-[1px] !rounded-[0px] border-b-[1px] border-transparent hover:border-white"
            onClick={() => navigate('/terms-conditions')}
          />
        </div>
        <Typography tag="b" variant="body-2" className="text-gray-500 mobile:flex hidden">
          © {new Date().getFullYear()} Roadifix Inc. All rights reserved
        </Typography>
      </div>
    </footer>
  );
};

export default Footer;
