import { Outlet } from 'react-router-dom';

import NewPageTitle from '../components/layout/admin/new-page-title';
import Container from '../components/layout/container';
import AdminHeader from '../components/layout/header/admin';
import Sidebar from '../components/layout/sidebar';
import MobileSidebar from '../components/layout/sidebar/mobile';
import ChatBot from '../pages/chat-bot';

const InvoiceLayout = () => {
  return (
    <Container>
      <AdminHeader />
      <div className="w-full h-full flex flex-row items-start mobile:overflow-auto mobile:mt-[90px] bg-[#F9F9F9] mobile:h-[calc(100vh_-_180px)]">
        <Sidebar className="mobile:hidden" />
        <div className="relative w-full overflow-auto p-[30px] medium:p-[20px] h-[calc(100vh_-_90px)] mobile:py-[30px] mobile:px-[20px]">
          <NewPageTitle />
          <Outlet />
          <ChatBot />
        </div>
      </div>
      <MobileSidebar className="hidden mobile:flex mobile:h-[90px]" />
    </Container>
  );
};

export default InvoiceLayout;
