import { Outlet } from 'react-router-dom';

import Container from '../components/layout/container';
import AdminHeader from '../components/layout/header/admin';
import Sidebar from '../components/layout/sidebar';
import MobileSidebar from '../components/layout/sidebar/mobile';
import ChatBot from '../pages/chat-bot';

const AdminLayout = () => {
  return (
    <Container>
      <AdminHeader />
      <div className="w-full h-full flex flex-row xl:items-start justify-start overflow-auto mobile:mt-[90px]">
        <Sidebar className=" mobile:hidden" />
        <div className="relative w-full overflow-auto p-[30px] mobile:!p-[15px] medium:p-[20px] bg-[#F9F9F9] mobile:h-[calc(100vh_-_180px)] h-[calc(100vh_-_90px)]">
          <Outlet />
          <ChatBot />
        </div>
      </div>
      <MobileSidebar className="hidden mobile:flex mobile:h-[90px]" />
    </Container>
  );
};

export default AdminLayout;
