import { memo } from 'react';

import { useNavigate } from 'react-router-dom';

import useBack from '../../../hooks/use-back';
import Button from '../input-sources/button';

interface IGoBack {
  content?: boolean;
  admin?: boolean;
  backUrl?: string;
}

const GoBack = ({ admin, content, backUrl = '' }: IGoBack) => {
  const { goBack } = useBack();
  const navigate = useNavigate();

  const onButtonClick = () => {
    backUrl ? navigate(backUrl) : goBack();
  };

  if (admin)
    return (
      <Button
        icon="chevron-left"
        iconClassName="stroke-black-1 w-[20px] h-[20px] medium:!w-[16px] medium:!h-[16px]"
        className="gap-[5px] z-10 medium:!text-base medium:h-[40px]"
        label="Back"
        size="back-admin"
        variant="admin-back"
        onClick={onButtonClick}
      />
    );

  if (content)
    return (
      <Button
        icon="chevron-left"
        iconClassName="stroke-black-1 w-[20px] h-[20px] medium:w-[16px] medium:h-[16px] hover:stroke-white-bh"
        label="BACK"
        size="text-lg"
        variant="text-black"
        className="mobile:hidden flex !not-italic !h-[44px] !font-medium text-black-1 !p-[10px]"
        onClick={onButtonClick}
      />
    );

  return (
    <Button
      icon="chevron-left"
      iconClassName="stroke-white w-[20px] h-[20px] medium:w-[16px] medium:h-[16px]"
      label="Back"
      size="text-lg"
      variant="text"
      className="hidden mobile:flex absolute left-2 !text-base mobile:p-[10px]"
      onClick={onButtonClick}
    />
  );
};

export default memo(GoBack);
