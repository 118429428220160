import { useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import companyService from '../../../core/api/company-service';
import useBack from '../../../hooks/use-back';
import { setCurrentUser } from '../../../store/actions/auth';
import { setCompany } from '../../../store/actions/company';
import { setCompanyInfo } from '../../../store/actions/invoice';
import { setLoader } from '../../../store/actions/loader';
import { currentUserSelector } from '../../../store/selectors/auth';
import { companySelector } from '../../../store/selectors/company';
import { RolesEnum } from '../../../utils/enums';
import Button from '../../shared/input-sources/button';
import Logo from '../../shared/logo';
import SvgIcon from '../../shared/svg-icon';
import Typography from '../../shared/typography';

const AdminHeader = () => {
  const dispatch = useDispatch();
  const authUser = useSelector(currentUserSelector);
  const company = useSelector(companySelector);
  const navigate = useNavigate();
  const location = useLocation();
  const { companyId } = useParams();
  const { goBack } = useBack();

  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const settingsWindowRef = useRef<any>(null);
  const settingsButtonRef = useRef<any>(null);
  const [isOpenMobileModal, setIsOpenMobileModal] = useState(false);

  const handleUnauthorized = () => {
    dispatch(setCurrentUser(null));
    dispatch(setCompany(null));
    dispatch(setLoader(false));
  };

  useEffect(() => {
    window.addEventListener('unauthorized', handleUnauthorized);

    return () => {
      window.removeEventListener('unauthorized', handleUnauthorized);
    };
  }, []);

  useEffect(() => {
    const handleOutSideClick = (event: MouseEvent) => {
      if (settingsWindowRef.current && !settingsWindowRef.current.contains(event.target as Element)) {
        if (isOpenDropdown && settingsButtonRef.current && !settingsButtonRef.current.contains(event.target as Element))
          setIsOpenDropdown(false);
      }
    };

    if (isOpenDropdown) {
      document.addEventListener('mousedown', handleOutSideClick);
    } else {
      document.removeEventListener('mousedown', handleOutSideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutSideClick);
    };
  }, [isOpenDropdown]);

  useEffect(() => {
    if (!companyId) {
      dispatch(setCompany(null));
    } else {
      if (!company && !location.pathname.includes('invoice/')) {
        fetchCompany();
      }
    }
    if (!location.pathname.includes('invoice/')) {
      dispatch(setCompanyInfo(null));
    }
  }, [location.pathname]);

  const fetchCompany = async () => {
    const companyResponse = await companyService.getCompanyById();

    if (companyResponse) dispatch(setCompany(companyResponse));
  };

  const signOut = () => {
    dispatch(setCurrentUser(null));
    dispatch(setCompany(null));
    localStorage.removeItem('access_token');
    sessionStorage.removeItem('access_token');
    navigate('/');
  };

  const onExitClick = () => {
    navigate('/super-admin/vendor-listing');
    dispatch(setCompany(null));
    setIsOpenDropdown(false);
  };

  const getCompanyInfo = () => {
    if (company) {
      return company;
    } else if (authUser?.company) {
      return authUser.company;
    } else {
      return null;
    }
  };

  const getRedirectUrl = () => {
    const userRole = authUser?.roles[0];

    if (userRole) {
      if (userRole === RolesEnum.SuperAdmin && company) {
        return `/super-admin/dashboard/${company.id}/invoices`;
      } else if (userRole === RolesEnum.Admin) {
        return `/admin/invoices`;
      } else {
        return `/employee/dashboard/invoices`;
      }
    }
    return '/';
  };

  return (
    <header
      className="h-[90px] bg-black-1 text-white flex flex-row items-center 
    justify-between px-[40px] mobile:fixed mobile:top-0 mobile:left-0 mobile:w-full mobile:px-[20px]"
    >
      <div className="w-full hidden mobile:flex justify-between items-center">
        {location.pathname.includes('new-authorization') ? (
          <div className="flex items-center gap-[5px]" aria-hidden onClick={goBack}>
            <SvgIcon icon={'chevron-left'} className={'w-[24px] h-[24px] stroke-white'} />
            <Typography tag="span" variant="card-title" className="font-medium">
              Back
            </Typography>
          </div>
        ) : location.pathname.includes('invoice/') ? (
          <>
            <div aria-hidden onClick={() => navigate(getRedirectUrl())}>
              <Typography tag="span" variant="card-title" className="font-medium">
                Exit
              </Typography>
            </div>
            <Logo color="white" size="invoice" noLink />
            <button onClick={() => setIsOpenMobileModal(true)} className="hidden medium:flex">
              <SvgIcon icon={'menu'} className={'w-[24px] h-[24px] stroke-white'} />
            </button>
          </>
        ) : (
          <>
            {getCompanyInfo() ? (
              <Typography tag="span" variant="card-title">
                {getCompanyInfo().name}
              </Typography>
            ) : (
              <Logo color="white" size="admin" noLink />
            )}
            <button onClick={() => setIsOpenMobileModal(true)} className="hidden medium:flex">
              <SvgIcon icon={'menu'} className={'w-[24px] h-[24px] stroke-white'} />
            </button>
          </>
        )}
      </div>
      <div className="min-w-[100px] flex flex-row items-center gap-[20px] mobile:hidden">
        <div className="sticky w-fit mobile:absolute left-0 right-0 m-auto">
          <Logo color="white" size="admin" />
        </div>
        {authUser?.roles && (
          <div className="flex items-center h-[36px] bg-[#38A0FF29] border border-blue-500 py-[6px] px-[16px] rounded-[31px] gap-2">
            <SvgIcon icon="user" className={'w-[20px] h-[20px] stroke-blue-500'} />
            <Typography tag="span" variant="none" className="font-medium text-base text-blue-500 capitalize">
              {authUser?.roles[0].toLowerCase()}
            </Typography>
          </div>
        )}
      </div>
      <div className={`w-full flex flex-row justify-end items-center gap-[20px] ml-auto mobile:hidden`}>
        <div className="h-full items-center flex mobile:hidden hover:bg-black-2 hover:cursor-pointer rounded-[10px]">
          {getCompanyInfo() ? (
            <>
              <div
                className="flex flex-row gap-[10px] items-center px-[10px] py-[15px]"
                ref={settingsButtonRef}
                aria-hidden
                onClick={() => setIsOpenDropdown(!isOpenDropdown)}
              >
                <img src={getCompanyInfo().image} width={60} height={60} className="rounded-[10px]" alt="companyImage"></img>
                <Typography tag="span" variant="nav-link">
                  {getCompanyInfo().name}
                </Typography>
                <SvgIcon icon="dropOpen" className={`w-[24px] h-[24px]`} />
              </div>
              {isOpenDropdown ? (
                <div
                  className="absolute z-50 w-[200px] bg-white top-[90px] right-[40px] 
                  rounded-b-[10px] shadow-[0_0_8px_0_rgba(0,0,0,0.25)] p-[5px] flex gap-[5px] flex-col"
                  ref={settingsWindowRef}
                >
                  {authUser?.roles[0] === RolesEnum.SuperAdmin ? (
                    <div
                      className="h-[40px] w-full medium:h-[30px] rounded-[10px] 
                      hover:bg-white-2 hover:cursor-pointer flex flex-row justify-between items-center px-[10px]"
                      aria-hidden
                      onClick={onExitClick}
                    >
                      <Typography tag="span" variant="nav-link" className="text-grey-500">
                        Exit Company
                      </Typography>
                      <SvgIcon icon="exitCompany" className="w-[24px] h-[24px] medium:w-[20px] medium:h-[20px]" />
                    </div>
                  ) : (
                    <></>
                  )}
                  <div
                    className="h-[40px] w-full medium:h-[30px] rounded-[10px] 
                    hover:bg-white-2 hover:cursor-pointer flex items-center px-[10px]"
                    aria-hidden
                    onClick={signOut}
                  >
                    <Typography tag="span" variant="nav-link" className="text-red-500">
                      Sign Out
                    </Typography>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </>
          ) : (
            <Button
              icon="log-out"
              label="Sign Out"
              iconClassName="stroke-white w-[24px] h-[24px] medium:w-[16px medium:h-[16px] hover:stroke-white-bh"
              variant="text"
              className="w-[148px] h-[50x] text-[20px] medium:text-base font-medium leading-normal !rounded-lg"
              onClick={signOut}
            />
          )}
        </div>
      </div>
    </header>
  );
};

export default AdminHeader;
