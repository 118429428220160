import { type FC, memo, Suspense } from 'react';

import { type DefaultLayoutProps } from '../../../@types';
import loaderImg from '../../../assets/images/loading.gif';

const Container: FC<DefaultLayoutProps> = ({ children }) => {
  return (
    <div className="overflow-hidden min-h-screen flex flex-col w-full m-auto">
      <Suspense
        fallback={
          <div className="fixed top-0 left-0 h-screen w-screen z-[10000] flex justify-center items-center bg-black opacity-60">
            <img src={loaderImg} alt="loading" width={80} height={80} />
          </div>
        }
      >
        {children}
      </Suspense>
    </div>
  );
};

export default memo(Container);
