import { useEffect, useRef, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import Button from '../../../shared/input-sources/button';
import CustomTooltip from '../../../shared/input-sources/tooltip';
import SvgIcon, { type Icon } from '../../../shared/svg-icon';

export interface IContact {
  icon: Icon;
  link?: string;
}

export interface IContactInfo {
  contacts: IContact[];
}

const ContactInfo = ({ contacts }: IContactInfo) => {
  const [activeElement, setActiveElement] = useState<any>(null);
  const [active, setActive] = useState<any>(null);
  const navigate = useNavigate();

  const text = (icon: string) => {
    switch (icon) {
      case 'phone':
        return '(888) 310-7773';
      case 'mail':
        return 'info@roadifix.com';
      default:
        return '';
    }
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (!!activeElement && !activeElement.contains(event.target as Element)) {
        setActiveElement('');
        setActive(null);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [activeElement]);

  return (
    <div className="flex flex-col items-center justify-center gap-[30px]">
      <div className="flex xl:flex-row medium:flex-row items-start xl:items-center medium:items-center gap-[20px] mobile:hidden">
        {contacts.map(({ icon, link }) => (
          <div key={icon} className="flex flex-row gap-[10px] items-center justify-center">
            <CustomTooltip content={text(icon as string) as string} hover="h">
              <div
                onClickCapture={event => {
                  link && window.open(link);
                  setActiveElement(event.target);
                }}
                className="w-[50px] h-[50px] flex justify-center items-center border-[0.5px] cursor-pointer border-white hover:bg-black backdrop-blur-sm bg-hero-gradient rounded-[12px] medium:w-[40px] medium:h-[40px]"
              >
                <SvgIcon icon={icon} className="stroke-white w-[24px] h-[24px]" />
              </div>
            </CustomTooltip>
          </div>
        ))}
      </div>
      <div className="mobile:flex hidden xl:flex-row medium:flex-row items-start xl:items-center medium:items-center gap-[20px] ">
        {contacts.map(({ icon, link }) => (
          <div key={icon} className="flex flex-row gap-[10px] items-center justify-center">
            <CustomTooltip content={text(icon as string) as string} hover="h" open={active == icon}>
              <div
                onClickCapture={event => {
                  link && window.open(link);
                  setActiveElement(event.target);
                  setActive(icon);
                }}
                className="w-[50px] h-[50px] flex justify-center items-center border-[0.5px] cursor-pointer border-white hover:bg-black backdrop-blur-sm bg-hero-gradient rounded-[12px] medium:w-[40px] medium:h-[40px]"
              >
                <SvgIcon icon={icon} className="stroke-white w-[24px] h-[24px]" />
              </div>
            </CustomTooltip>
          </div>
        ))}
      </div>
      <div className="xl:flex-row medium:flex-row gap-[14px] flex mobile:hidden">
        <Button
          variant="text"
          size="none"
          label="Privacy Policy"
          className="text-sm hover:border-b-[1px] !rounded-[0px] border-b-[1px] border-transparent hover:border-white"
          onClick={() => navigate('/privacy-policy')}
        />
        <Button
          variant="text"
          size="none"
          label="Terms & Conditions"
          className="text-sm hover:border-b-[1px] !rounded-[0px] border-b-[1px] border-transparent hover:border-white"
          onClick={() => navigate('/terms-conditions')}
        />
      </div>
    </div>
  );
};

export default ContactInfo;
