import { memo, type SVGProps } from 'react';

import icons from '../../../assets/svgs/icons.svg';

export type Icon =
  | 'logo'
  | 'chevron-down'
  | 'chevron-up'
  | 'chevron-left'
  | 'chevron-right'
  | 'clock'
  | 'map-pin'
  | 'phone'
  | 'mail'
  | 'globe'
  | 'hamburger'
  | 'close'
  | 'search'
  | 'tql'
  | 'transport-logistic'
  | 'ford'
  | 'truck-company'
  | 'mobile-truck-repair'
  | 'towing-and-recovery'
  | 'mobile-tire-service'
  | 'mobile-welding'
  | 'mobile-reefer-repair'
  | 'mobile-electrician'
  | 'menu'
  | 'order'
  | 'invoice'
  | 'receipt'
  | 'user'
  | 'users'
  | 'view'
  | 'chat'
  | 'settings'
  | 'file-check'
  | 'share'
  | 'edit'
  | 'plus-circled'
  | 'radio-off'
  | 'radio-on'
  | 'check-circled'
  | 'trash'
  | 'eye-open'
  | 'eye-closed'
  | 'arrow-up-from-line'
  | 'square'
  | 'check-square'
  | 'minus-square'
  | 'calendar'
  | 'card'
  | 'info'
  | 'lock'
  | 'grip-vertical'
  | 'google'
  | 'location'
  | 'invoices-products'
  | 'user-accounts'
  | 'bank-accounts'
  | 'invoices-product-taxes'
  | 'instant-payout'
  | 'agreements-templates'
  | 'company-information'
  | 'bank-account'
  | 'customers-account'
  | 'custom-fields'
  | 'deposits'
  | 'location-settings'
  | 'taxes'
  | 'send'
  | 'lucid'
  | 'update'
  | 'arrow-up-from-line-2'
  | 'show'
  | 'dashboard'
  | 'home'
  | 'text'
  | 'show-menu'
  | 'slack-down'
  | 'image-plus'
  | 'duplicate-invoice'
  | 'reload-dollar'
  | 'attache'
  | 'instagram'
  | 'save'
  | 'printer'
  | 'credit-card'
  | 'cash'
  | 'remote'
  | 'app-store'
  | 'google-play'
  | 'services'
  | 'log-out'
  | 'not-found-data'
  | 'clock'
  | 'check'
  | 'upload-cloud'
  | 'work-orders'
  | 'invoices'
  | 'deactivate'
  | 'activate'
  | 'view-file'
  | 'void'
  | 'no-data-found'
  | 'download'
  | 'create-invoice'
  | 'view-order'
  | 'send-receipt'
  | 'refund-invoice'
  | 'line-items'
  | 'parts-labor'
  | 'comments'
  | 'attachments'
  | 'dropOpen'
  | 'dropClose'
  | 'exitCompany'
  | 'email-opened-twotone'
  | 'complete-draft'
  | 'chat-massage'
  | 'processed'
  | 'processed-status'
  | 'error-message-icon'
  | 'Payments'
  | 'refunded'
  | 'phone-massage'
  | 'checked'
  | 'edit-text'
  | 'file-edit'
  | 'dollar'
  | 'reset'
  | 'menu-icon'
  | 'warning';

export interface ISvgIcon extends SVGProps<any> {
  icon: Icon;
  className?: string;
}

const SvgIcon = ({ icon, className, ...rest }: ISvgIcon) => {
  return (
    <svg className={`${className ? className : 'w-[24px] h-[24px] stroke-black-1'} icon ${icon}`} {...rest}>
      <use xlinkHref={`${icons}#${icon}`} />
    </svg>
  );
};

export default memo(SvgIcon);
