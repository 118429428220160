import axios from 'axios';

import { store } from '../../store';
import { RolesEnum } from '../../utils/enums';

const axiosClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/api`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

axiosClient.defaults.timeout = 5000;

axiosClient.defaults.withCredentials = true;

axiosClient.interceptors.request.use(
  config => {
    const token = sessionStorage.getItem('access_token') || localStorage.getItem('access_token');
    if (token) config.headers['Authorization'] = `Bearer ${token}`;

    const { company, auth } = store.getState();
    if (company.company) {
      config.headers['x-tenant-id'] = company.company.id;
    } else if (
      auth.user?.roles.includes(RolesEnum.Admin) ||
      (auth.user?.roles.includes(RolesEnum.Employee) && auth.user?.company.id)
    ) {
      config.headers['x-tenant-id'] = auth.user?.company.id;
    }

    return config;
  },
  error => Promise.reject(error),
);

axiosClient.interceptors.response.use(
  response => response,
  async error => {
    if (error.response && error.response.status === 401) {
      sessionStorage.getItem('access_token')
        ? sessionStorage.removeItem('access_token')
        : localStorage.removeItem('access_token');

      const unauthorizedEvent = new Event('unauthorized');
      window.dispatchEvent(unauthorizedEvent);
    }
    return Promise.reject(error);
  },
);

export default axiosClient;
