import JsPDF from 'jspdf';
import * as math from 'mathjs';

import { EARTH_RADIUS_MI } from '../constants';
import { TransactionStatuses } from '../enums';

export type Location = number[];

export const calculateDistance = (location1: Location, location2: Location): number => {
  const dLat = degreesToRadians(location2[1] - location1[1]);
  const dLon = degreesToRadians(location2[0] - location1[0]);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(degreesToRadians(location1[1])) * Math.cos(degreesToRadians(location2[1])) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = EARTH_RADIUS_MI * c;

  return distance;
};

export const isEmpty = (value: any) => value === undefined || value === null || value === '';
export const isNotEmpty = (value: any) => !isEmpty(value);

export const removeEmptyFields = (obj: any) => {
  const result: any = {};

  for (const key in obj) {
    if (isNotEmpty(obj[key])) result[key] = obj[key];
  }

  return result;
};

export const degreesToRadians = (degrees: number): number => degrees * (Math.PI / 180);

export const scrollToBottom = (id: string) => {
  const content = document.getElementById(id);

  if (content) content.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'end' });
};

export const formatFileSize = (bytes: number): string => {
  if (bytes < 1024) {
    return bytes + ' b';
  } else if (bytes < 1024 * 1024) {
    return (bytes / 1024).toFixed(2) + ' kb';
  } else {
    return (bytes / (1024 * 1024)).toFixed(2) + ' mb';
  }
};

export const objectToFormData = (obj: any) => {
  obj = removeEmptyFields(obj);

  const formData = new FormData();

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      if (Array.isArray(obj[key])) {
        obj[key].forEach((value: any) => {
          formData.append(key + '[]', value);
        });
      } else {
        formData.append(key, obj[key]);
      }
    }
  }

  return formData;
};

export const generatePDF = (text: string) => {
  const doc = new JsPDF();

  doc.setFontSize(12);
  const splitText = doc.splitTextToSize(text, 190);
  doc.text(splitText, 10, 10);

  doc.save('preview.pdf');
};

export const dataURItoBlob = (dataURI: string) => {
  const byteString = atob(dataURI.split(',')[1]);

  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  const ab = new ArrayBuffer(byteString.length);
  const dw = new DataView(ab);
  for (let i = 0; i < byteString.length; i++) {
    dw.setUint8(i, byteString.charCodeAt(i));
  }

  return new Blob([ab], { type: mimeString });
};

export const emailRegex =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

export const phoneRegex = /^\d{10}$/;

export const isValidEmail = (str: string) => {
  return emailRegex.test(str);
};

export const isValidPhone = (str: string) => {
  return phoneRegex.test(str);
};

export const openInNewTab = (url: string) => {
  const newTab = window.open(url, '_blank');

  if (newTab) {
    newTab.focus();
  }
};

export const checkInvoiceStatus = (invoice: any) => {
  if (invoice.transaction?.status) {
    if (invoice.transaction.status === TransactionStatuses.Success) {
      return {
        icon: 'check',
        color: 'stroke-blue-500',
        textColor: 'text-blue-500',
        status: 'Paid',
      };
    } else if (invoice.transaction.status === TransactionStatuses.Pending) {
      return {
        icon: 'clock',
        color: 'stroke-gray-500',
        textColor: 'text-gray-600',
        status: 'Pending',
      };
    } else if (invoice.transaction.status === TransactionStatuses.Refunded) {
      return {
        icon: 'refunded',
        color: 'stroke-yellow-500',
        textColor: 'text-yellow-500',
        status: 'Refunded',
      };
    } else {
      return {
        icon: 'close',
        color: 'stroke-red-500 p-[5px]',
        textColor: 'text-red-500',
        status: 'Failed',
      };
    }
  } else if (invoice.remoteCheckout) {
    return {
      icon: 'processed-status',
      color: 'stroke-gray-500',
      textColor: 'text-slate-600',
      status: 'Processed',
    };
  } else {
    return {
      icon: '',
      color: '',
      status: '-',
    };
  }
};

export const checkInvoiceDepositStatus = (invoice: any) => {
  if (
    invoice.transaction &&
    (invoice.transaction.status === TransactionStatuses.Success || invoice.transaction.status === TransactionStatuses.Refunded)
  ) {
    if (invoice.depositStatus) {
      return {
        icon: 'check',
        color: 'stroke-blue-500',
        textColor: 'text-blue-500',
        status: 'Paid',
      };
    } else {
      return {
        icon: 'clock',
        color: 'stroke-gray-500',
        textColor: 'text-gray-600',
        status: 'Pending',
      };
    }
  } else {
    return {
      icon: '',
      color: '',
      status: '-',
    };
  }
};

export const countInvoiceAmount = (invoice: any) => {
  const productsAmount = invoice?.products
    ? invoice?.products?.reduce(
        (acc: number, curr: any) =>
          acc +
          (curr.taxable
            ? ((invoice.company?.taxes?.length ? +invoice.company.taxes[0]?.percentTaxAmount : 0) *
                (+curr.cost * +curr.quantity)) /
                100 +
              +curr.cost * +curr.quantity
            : +curr.cost * +curr.quantity),
        0,
      )
    : +0;

  const lineItemsAmount =
    invoice?.lineItems?.reduce(
      (acc: number, curr: any) =>
        acc +
        (curr.taxable
          ? ((invoice.company?.taxes?.length ? +invoice.company.taxes[0].percentTaxAmount : 0) * +curr.cost) / 100 + +curr.cost
          : +curr.cost),
      0,
    ) || +0;

  if (!invoice.convenienceFee) return +math.format(+productsAmount + +lineItemsAmount, { notation: 'fixed', precision: 2 });
  const conFee = ((productsAmount + lineItemsAmount) * 3.5) / 100;

  return +math.format(+productsAmount + +lineItemsAmount + +conFee, { notation: 'fixed', precision: 2 });
};

export const formattedAmount = (amount: number) => {
  if (amount) {
    const floatValue = parseFloat(amount.toString());
    const roundedValue = floatValue.toFixed(2);
    const formattedString = parseFloat(roundedValue).toLocaleString('en-US');

    return formattedString;
  } else return 0;
};

export const countInvoiceAmountInfo = (invoice: any, tax = 0) => {
  const productsAmount =
    invoice?.products?.reduce(
      (acc: number, curr: any) =>
        acc +
        (curr.taxable
          ? ((invoice.tax || tax) * (+curr.cost * +curr.quantity)) / 100 + +curr.cost * +curr.quantity
          : +curr.cost * +curr.quantity),
      0,
    ) || 0;

  const productsTaxAmount =
    invoice?.products?.reduce(
      (acc: number, curr: any) => acc + (curr.taxable ? ((invoice.tax || tax) * (+curr.cost * +curr.quantity)) / 100 : 0),
      0,
    ) || 0;

  const lineItemsAmount =
    invoice?.lineItems?.reduce(
      (acc: number, curr: any) => acc + (curr.taxable ? ((invoice.tax || tax) * +curr.cost) / 100 + +curr.cost : +curr.cost),
      0,
    ) || 0;

  const lineItemsTaxAmount =
    invoice?.lineItems?.reduce(
      (acc: number, curr: any) => acc + (curr.taxable ? ((invoice.tax || tax) * +curr.cost) / 100 : 0),
      0,
    ) || 0;

  const conFee = ((productsAmount + lineItemsAmount) * 3.5) / 100;

  return {
    amount: +math.format(+productsAmount + +lineItemsAmount, { notation: 'fixed', precision: 2 }),
    productsTax: productsTaxAmount,
    lineItemsTax: lineItemsTaxAmount,
    conFee: invoice.convenienceFee ? conFee : 0,
  };
};

export const isValidNumber = (str: string) => {
  return !isNaN(parseFloat(str));
};

export const generateRandomString = (length: number) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  const result = Array.from({ length }, () => characters[Math.floor(Math.random() * charactersLength)]).join('');

  return result;
};

export const formatPhoneNumber = (value: number) => {
  const cleaned = ('' + value).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return `${match[1]}-${match[2]}-${match[3]}`;
  }

  return `${value}`;
};
