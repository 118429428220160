import axiosClient from '../';

class UserService {
  private readonly path: string;

  constructor() {
    this.path = '/user';
  }

  public async getCurrentUser() {
    try {
      const { data } = await axiosClient.get(`${this.path}/me`);
      return data;
    } catch (e) {
      console.error(e);
    }
  }

  public async getUsers() {
    try {
      const requestPath = this.path;

      const { data } = await axiosClient.get(requestPath);
      return data;
    } catch (e) {
      console.error(e);
    }
  }

  public async getUser(id: number) {
    try {
      const { data } = await axiosClient.get(`${this.path}/${id}`);
      return data;
    } catch (e) {
      console.error(e);
    }
  }

  public async addUserInfo(userData: any) {
    try {
      const { data } = await axiosClient.post(this.path, userData);
      return data;
    } catch (e: any) {
      return e.response.data;
    }
  }

  public async deleteUser(id: number) {
    try {
      const response: any = await axiosClient.delete(`${this.path}/${id}`);
      return response;
    } catch (e) {
      console.error(e);
    }
  }

  public async editUserInfo(id: number, userData: any) {
    try {
      const { data } = await axiosClient.patch(`${this.path}/${id}`, userData);
      return data;
    } catch (e: any) {
      return e.response.data;
    }
  }
}

export default new UserService();
