import { useSelector } from 'react-redux';

import Navigation from './navigation';
import { sidebarVisibleSelector } from '../../../store/selectors/header';

const Sidebar = ({ className }: any) => {
  const sidebarVisible = useSelector(sidebarVisibleSelector);

  const visibilityClasses = sidebarVisible ? 'w-fit min-w-[290px] p-[20px]' : 'w-0 p-0';

  return (
    <div
      className={`${visibilityClasses} overflow-x-hidden overflow-y-auto h-[calc(100vh_-_90px)] bg-[#2A2A2A] transition-all ${className}`}
    >
      {sidebarVisible && <Navigation />}
    </div>
  );
};

export default Sidebar;
